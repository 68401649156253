import { CircularProgress, Grid, Typography } from "@mui/material";
import { VTAudioRecorderAndPlayback, VTDialog } from "@virtus-tech-repository/virtus-tech-repository";
import { useEffect, useRef, useState } from "react";
import { useCreateAllAudioMutation, usePreSignedUrlMutation } from "../../services/media.service";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { v4 as uuid } from "uuid";
import { uploadMedia } from "../../store/thunks/upload.thunks";
import { setCurrentToast } from "../../store/slices/current.slice";
import { setMediaUploadPercentage } from "../../store/slices/mediaUpload.slice";
import AlertImage from "../../assets/images/undraw_notify_rnwe.svg";

interface IProps {
    open: boolean;
    handleClose: () => void;
}

export default function VTAudioRecord({ open, handleClose }: IProps) {
    const [preSignedUrl, { data: uploadUrl, isLoading: preSignedUrlLoading }] = usePreSignedUrlMutation();
    const { mediaUploadPercentage, mediaUploadCompleted, mediaUploadError, mediaUploading } = useAppSelector(
        (state) => state.mediaUploadReducer,
    );

    const { currentSidePanel, currentPage } = useAppSelector((state) => state.currentReducer);
    const { organisation, id: userId } = useAppSelector((state) => state.userReducer);

    const [createAllAudio] = useCreateAllAudioMutation();

    const dispatch = useAppDispatch();

    const [audioSource, setAudioSource] = useState<string>("");
    const [audioId, setAudioId] = useState<string>("");

    const [audioName, setAudioName] = useState<string>("");

    const [submittedAudioRecordingForUpload, setSubmittedAudioRecordingForUpload] = useState<boolean>(false);

    const defaultAudioTitleText = "name and save here";

    useEffect(() => {
        setAudioName("");
        setAudioId(uuid());
        if (open) {
            setSubmittedAudioRecordingForUpload(false);
            dispatch(setMediaUploadPercentage(0));
        }
    }, [open]);

    useEffect(() => {
        uploadAudio();
    }, [uploadUrl]);

    useEffect(() => {
        if (mediaUploadCompleted && audioSource) {
            createAllAudio({
                id: userId,
                media_id: audioId,
                media_type: "audio",
                filetype: "mp3",
                name: audioName,
                description: "",
                date: Date.now(),
                tags: [],
                preview: false,
            });
            handleClose();
        }
    }, [mediaUploadCompleted]);

    async function uploadAudio() {
        if (uploadUrl && audioSource) {
            const audioFile = await dataUrlToFile(audioSource, audioId);
            dispatch(
                uploadMedia({
                    media: audioFile,
                    url: uploadUrl.url,
                    dispatch: dispatch,
                }),
            );
        }
    }

    async function dataUrlToFile(dataUrl: string, fileName: string): Promise<File> {
        const res: Response = await fetch(dataUrl);
        const blob: Blob = await res.blob();
        return new File([blob], fileName, { type: "image/png" });
    }

    async function upload() {
        await preSignedUrl({
            organisation,
            media_type: "audio",
            media_id: audioId,
            filetype: "mp3",
        });
    }

    return (
        <VTDialog
            open={open}
            handleClose={handleClose}
            title={"Record Audio"}
            sx={
                currentPage === "creator"
                    ? { marginLeft: `${currentSidePanel.currentWidth + 100}px` }
                    : { marginLeft: `${currentSidePanel.currentWidth}px` }
            }
        >
            <Grid
                xs={12}
                container
                item
                sx={{ width: "100%", height: "400px" }}
                direction={"column"}
                alignItems={"center"}
                justifyContent={"center"}
            >
                {submittedAudioRecordingForUpload ? (
                    <CircularProgress />
                ) : (
                    <VTAudioRecorderAndPlayback
                        sx={{ width: "420px" }}
                        defaultAudioTitleRecordingName={defaultAudioTitleText}
                        autoSelectTextOnEdit={true}
                        onAudioDataUrlReady={(audioSrcUrl: string) => {
                            setAudioSource(audioSrcUrl);
                        }}
                        onAudioTitleEdited={(audioTitle: string) => {
                            if (audioTitle.length > 0) {
                                if (audioTitle === defaultAudioTitleText) {
                                    setAudioName(audioId);
                                } else {
                                    setAudioName(audioTitle);
                                }
                                upload();
                                setSubmittedAudioRecordingForUpload(true);
                            } else {
                                dispatch(
                                    setCurrentToast({
                                        id: "audio_record",
                                        message: "must enter a name for audio recording!",
                                        component: <img src={AlertImage} height={"100px"} />,
                                        height: "180px",
                                    }),
                                );
                            }
                        }}
                    />
                )}

                {submittedAudioRecordingForUpload ? (
                    <Typography sx={{ marginTop: "20px" }} variant="h4">
                        {`uploading audio ${mediaUploadPercentage}%`}
                    </Typography>
                ) : (
                    <Typography sx={{ marginTop: "20px" }} variant="caption">
                        record, play, name and upload your audio
                    </Typography>
                )}
            </Grid>
        </VTDialog>
    );
}
