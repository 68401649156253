//---------------------------------------------------------------------------------------------------
// ## IMPORTS ##
// Library Imports
import { useRef, useState, useEffect, Ref, useMemo } from "react";
import { v4 as uuid } from "uuid";
import ReactAudioPlayer from "react-audio-player";

// Material ui Imports
import { Grid, SxProps, Typography, useTheme } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

// Custom components imports (and hooks and helpers)
import { EDraggableOptions, IMediaItem } from "@virtus-tech-repository/virtus-tech-repository/lib/models/media.model";
import {
    useLazyLoad,
    VTButton,
    VTSidePanelAudioItem,
    VTTextField,
    VTTypography,
} from "@virtus-tech-repository/virtus-tech-repository";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
    useCreateAllAudioMutation,
    useDeleteMediaMutation,
    useGetAllAudioQuery,
    usePreSignedUrlMutation,
    useUpdateAllScenarioMediaMutation,
} from "../../services/media.service";
import { uploadMedia } from "../../store/thunks/upload.thunks";
import { setMediaUploadCleanUp } from "../../store/slices/mediaUpload.slice";
import NoAudios from "../../assets/images/NoAudios.svg";
import { Params, useParams } from "react-router-dom";

import SearchNotFound from "../../assets/images/NoAudios.svg";
import VTAudioRecord from "./VTAudioRecord";
import { setCurrentDoubleClick } from "../../store/slices/current.slice";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { EDroppableContainerId } from "@virtus-tech-repository/virtus-tech-repository/lib/models/DragAndDrop.models";
import { ExposedVTSidePanelAudioItemfMethodsObject } from "@virtus-tech-repository/virtus-tech-repository/lib/components/VTSidePanelAudioItem";
import VTMediaUploader from "./VTMediaUploader";
import WordingHelper from "../../helpers/wordingHelper";
import { setAudioPrevPagesLoaded } from "../../store/slices/previouslyLazyLoadedMedia.slice";

//---------------------------------------------------------------------------------------------------
// ## MUI STYLED ##
// Layout stuff remains inline, other styling pulled into it's own components

//---------------------------------------------------------------------------------------------------
// ## INTERFACES ##
interface IProps {
    sx?: SxProps;
    onHandleDragStart?: (mediaType: EDraggableOptions, src: string) => void;
    onHandleDragStop?: () => void;
    videoCurrentState?: "Paused" | "Playing" | "Question" | undefined;
    audios?: IMediaItem[];
    audiosLoading: boolean;
}

//---------------------------------------------------------------------------------------------------
// ## COMPONENTS ##
// Only display layouts in pages
export function VTAudioSidePanel({
    sx,
    onHandleDragStart,
    audios,
    audiosLoading,
    onHandleDragStop,
    videoCurrentState,
}: IProps) {
    //-------------------------------------------------------------------------------------------------
    // ## HOOKS ##
    const inputFileRef = useRef<HTMLInputElement>(null);
    const theme = useTheme();

    const { id: userId, organisation } = useAppSelector((state) => state.userReducer);

    const [preSignedUrl, { data: uploadUrl, isLoading: preSignedUrlLoading }] = usePreSignedUrlMutation();

    const { mediaUploadPercentage, mediaUploadCompleted, mediaUploadError, mediaUploading } = useAppSelector(
        (state) => state.mediaUploadReducer,
    );
    const { scenarioId, sceneId }: Readonly<Params<string>> = useParams();

    const [createAllAudio] = useCreateAllAudioMutation();

    const [selectedAudio, setSelectedAudio] = useState<File | null>(null);
    const [selectedAudioError, setSelectedAudioError] = useState<boolean>(false);
    const [audioName, setAudioName] = useState<string>("");
    const [audioId, setAudioId] = useState<string>(uuid());

    const [highlight, setHighlight] = useState(false);

    const dispatch = useAppDispatch();
    //-------------------------------------------------------------------------------------------------
    // ## USE SELECTOR ##
    const { currentQuestion, editHotspotDialogIsCurrentlyOpen, currentlyDragging, currentSidePanel } = useAppSelector(
        (state) => state.currentReducer,
    );

    const audioItemGridSize = currentSidePanel.currentWidth === 400 ? 6 : 12;
    //-------------------------------------------------------------------------------------------------
    // ## USE STATE ##
    const [activeButton, setActiveButton] = useState(0);
    const [searchValue, setSearchValue] = useState<string>("");
    const { data: scenarioAudios, isLoading: scenarioAudiosLoading } = useGetAllAudioQuery(scenarioId!);
    const [updateAllScenarioMedia, { isLoading: updateLoading }] = useUpdateAllScenarioMediaMutation();
    const [deleteAllScenarioMedia, { isLoading: deleteLoading }] = useDeleteMediaMutation();

    const [currentItemUpdate, setCurrentItemUpdate] = useState<string | undefined>(undefined);
    const [recordModelOpen, setRecordModelOpen] = useState<boolean>(false);

    //-------------------------------------------------------------------------------------------------
    // ## USE EFFECTS ##
    useEffect(() => {
        if (mediaUploadCompleted && selectedAudio) {
            createAllAudio({
                id: userId,
                media_id: audioId,
                media_type: "audio",
                filetype: selectedAudio.type.split("/")[1],
                name: audioName,
                description: "",
                date: Date.now(),
                tags: [],
                preview: false,
            });
        }
    }, [mediaUploadCompleted]);
    //-------------------------------------------------------------------------------------------------
    // ## CUSTOM FUNCTIONS ##

    const handleButtonClick = (buttonIndex: number) => {
        setActiveButton(buttonIndex);
    };

    function preventDefaults(e: React.DragEvent) {
        e.preventDefault();
        e.stopPropagation();
    }

    console.log("audios", audios);

    async function handleFileAdded(audioFile: File) {
        // TODO: in the future only allow certain image types
        if (audioFile.type.includes("audio/")) {
            setSelectedAudioError(false);
            setSelectedAudio(audioFile);
            setAudioName(audioFile.name.split(".")[0]);

            // Get presigned url to upload image
            await preSignedUrl({
                organisation,
                media_type: "audio",
                media_id: `${audioId}`,
                filetype: audioFile.type.split("/")[1],
            });
        } else {
            setSelectedAudioError(true);
        }
    }

    // Function that handle audio added from dropping and dragging in
    function handleDrop(e: React.DragEvent) {
        preventDefaults(e);
        setHighlight(false);

        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            handleFileAdded(e.dataTransfer.files[0]);
        }
    }

    // Function that handle audio added from file system
    function handleSelect(e: React.ChangeEvent<HTMLInputElement>) {
        setHighlight(false);

        if (e.target.files && e.target.files.length > 0) {
            handleFileAdded(e.target.files[0]);
        }
    }

    function handleAudioUpload() {
        if (uploadUrl && selectedAudio) {
            dispatch(
                uploadMedia({
                    media: selectedAudio,
                    url: uploadUrl.url,
                    dispatch: dispatch,
                }),
            );
        }
    }

    function handleUploadCleanUp() {
        setSelectedAudio(null);
        setAudioId(uuid());
        setAudioName("");
        dispatch(setMediaUploadCleanUp());
        setActiveButton(0);
    }

    function handleDoubleClick(mediaType: EDraggableOptions, src: string) {
        dispatch(setCurrentDoubleClick({ src: src, mediaType: mediaType }));
    }

    const audioItemRefs = useRef<Map<string, ExposedVTSidePanelAudioItemfMethodsObject>>(new Map());
    const lastAudioItemPlayedIdRef = useRef<string | null>(null);

    const triggerRef = useRef<HTMLDivElement>(null);

    const audioFilesToLoadPerLazyLoad = 6;

    const lazyLoadTimeInMs = 1000;

    const currentPageRef = useRef<number>(0);

    const { sidePanelMedia, creatorComponentFirstMountedDate } = useAppSelector(
        (state) => state.previouslyLazyLoadedMediaReducer,
    );

    const initialMediaLoadedRef = useRef<boolean>();
    const initialPagesToConsiderRef = useRef<number>();
    useEffect(() => {
        // only run this code on component mount
        initialMediaLoadedRef.current = sidePanelMedia.audio.prevPagesLoaded === 0 ? true : false;
        initialPagesToConsiderRef.current =
            sidePanelMedia.audio.prevPagesLoaded! > 0 ? sidePanelMedia.audio.prevPagesLoaded! - 1 : 0;
    }, []);

    const onGrabData = (currentPage: number): Promise<IMediaItem[]> => {
        currentPageRef.current = currentPage;

        return new Promise((resolve) => {
            if (!audiosLoading && creatorComponentFirstMountedDate !== null) {
                if (audios) {
                    if (initialMediaLoadedRef.current) {
                        setTimeout(() => {
                            const startingIndex =
                                (initialPagesToConsiderRef.current! + currentPage - 1) * audioFilesToLoadPerLazyLoad;

                            const endIndex =
                                (initialPagesToConsiderRef.current! + currentPage) * audioFilesToLoadPerLazyLoad;

                            let data = audios
                                .slice()
                                .filter((item: IMediaItem) => item.date < creatorComponentFirstMountedDate!)
                                .sort((a: IMediaItem, b: IMediaItem) => {
                                    return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                })
                                .slice(startingIndex, endIndex);
                            resolve(data);
                        }, lazyLoadTimeInMs);
                    } else {
                        let data = audios
                            .slice()
                            .filter((item: IMediaItem) => item.date < creatorComponentFirstMountedDate!)
                            .sort((a: IMediaItem, b: IMediaItem) => {
                                return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                            })
                            .slice(0, sidePanelMedia.audio.prevPagesLoaded! * audioFilesToLoadPerLazyLoad);
                        resolve(data);
                        initialMediaLoadedRef.current = true;
                    }
                }
            }
        });
    };

    const [dataLength, setDataLength] = useState<number>(
        audios ? audios.filter((item: IMediaItem) => item.date < creatorComponentFirstMountedDate!).length : 0,
    );

    const audiosScrollContainerRef = useRef<HTMLDivElement>(null);

    const { data: lazyLoadedAudios, loading: lazyLoadingInProgress } = useLazyLoad<IMediaItem>({
        triggerRef,
        onGrabData,
        dataLength,
        scrollContainerRef: audiosScrollContainerRef,
        activeButton: activeButton,
    });

    let recentlyUploadedAudios = audios
        ? audios.slice().filter((mediaItem: IMediaItem) => {
              return mediaItem.date > creatorComponentFirstMountedDate!;
          })
        : [];

    const loadedAudioDataAndRecentUploadedAudios = [
        recentlyUploadedAudios!.sort((a, b) => {
            return new Date(b.date).valueOf() - new Date(a.date).valueOf();
        }),
        lazyLoadedAudios!,
    ].flat();

    useEffect(() => {
        dispatch(setAudioPrevPagesLoaded(Math.ceil(lazyLoadedAudios.length / audioFilesToLoadPerLazyLoad)));
    }, [lazyLoadedAudios]);

    return (
        <Grid
            container
            xs={12}
            sx={{ overflowY: "hidden", userSelect: "none", ...sx }}
            justifyContent={"center"}
            // alignContent={"flex-start"}
        >
            {/*-----------------------------------------------------------------------------------------*/}
            {/* Audio search */}
            <Grid item xs={11}>
                <VTTextField
                    sx={{
                        marginTop: "20px",
                    }}
                    value={searchValue}
                    onChange={(text: string) => {
                        setSearchValue(text);
                    }}
                    placeholder={"Search Audio"}
                    type={"text"}
                    endIcon={<SearchRoundedIcon />}
                />
            </Grid>
            <Grid
                item
                container
                xs={11}
                sx={{
                    marginTop: "15px",
                }}
            >
                <Grid item xs={4}>
                    <VTButton
                        onClick={() => handleButtonClick(0)}
                        sx={{
                            borderBottom: activeButton === 0 ? `3px solid ${theme.palette.primary.main}` : "none",
                            maxWidth: "100%",
                        }}
                        type="underline"
                    >
                        <Typography fontSize={"1em"}>All Audio</Typography>
                    </VTButton>
                </Grid>

                <Grid item xs={4}>
                    {
                        <VTButton
                            onClick={() => handleButtonClick(2)}
                            sx={{
                                borderBottom: activeButton === 2 ? `3px solid ${theme.palette.primary.main}` : "none",
                                maxWidth: "100%",
                            }}
                            type="underline"
                        >
                            <Typography fontSize={"1em"}>{WordingHelper(organisation)} Audio</Typography>
                        </VTButton>
                    }
                </Grid>

                <Grid item xs={4}>
                    <VTButton
                        onClick={() => handleButtonClick(1)}
                        sx={{
                            borderBottom: activeButton === 1 ? `3px solid ${theme.palette.primary.main}` : "none",
                            maxWidth: "100%",
                        }}
                        type="underline"
                    >
                        <Typography fontSize={"1em"}>Upload Audio </Typography>
                    </VTButton>
                </Grid>
            </Grid>

            {/* -------------------------------------------------------------------------------------- */}
            {/* Use the ImageSidePanel to help you map the audio files. Put the Audio data in the storybook.  */}
            {/* display each audio item in a paper component */}
            {/* Have the audio display title and then use npm audio player */}
            {/* Have the values inside the paper component */}
            {/* -------------------------------------------------------------------------------------- */}

            <VTAudioRecord open={recordModelOpen} handleClose={() => setRecordModelOpen(false)} />

            {/*-----------------------------------------------------------------------------------------*/}
            {/* Audio upload */}
            {activeButton === 1 && (
                <VTMediaUploader
                    context={"Audio"}
                    onComplete={() => setActiveButton(0)}
                    sx={{ overflowX: "auto", height: "calc(100vh - 224px)" }}
                />
            )}

            {activeButton === 0 && (
                <>
                    {audiosLoading ? (
                        <Grid item container columnSpacing={1} sx={{ padding: "20px", marginTop: "5px" }}>
                            <Grid
                                item
                                xs={audioItemGridSize}
                                sx={{
                                    marginBottom: "8px",
                                }}
                            >
                                <VTSidePanelAudioItem name={""} audioSrc={""} loading />
                            </Grid>
                            <Grid
                                item
                                xs={audioItemGridSize}
                                sx={{
                                    marginBottom: "8px",
                                }}
                            >
                                <VTSidePanelAudioItem name={""} audioSrc={""} loading />
                            </Grid>
                            <Grid
                                item
                                xs={audioItemGridSize}
                                sx={{
                                    marginBottom: "8px",
                                }}
                            >
                                <VTSidePanelAudioItem name={""} audioSrc={""} loading />
                            </Grid>
                            <Grid
                                item
                                xs={audioItemGridSize}
                                sx={{
                                    marginBottom: "8px",
                                }}
                            >
                                <VTSidePanelAudioItem name={""} audioSrc={""} loading />
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            {!audios ? ( // error handle for a failed request to get audios
                                <>error</>
                            ) : audios.length === 0 ? ( // no uploaded audios
                                <Grid item xs={12} textAlign={"center"} sx={{ marginTop: "5px" }}>
                                    <img src={NoAudios} style={{ width: "90%", marginTop: "20px" }} />
                                    <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                        To get started, add first audio file
                                    </VTTypography>
                                </Grid>
                            ) : ( // uploaded audios
                                <Grid
                                    sx={{
                                        marginTop: "5px",
                                        padding: "20px",
                                        overflowY: "auto",
                                        // overflowX: "hidden",
                                        height: "calc(100vh - 225px)",
                                        width: "100%",
                                    }}
                                    item
                                    container
                                    alignContent={"flex-start"}
                                    ref={audiosScrollContainerRef}
                                >
                                    {audios?.filter(
                                        (item: IMediaItem) =>
                                            searchValue === "" ||
                                            item.name!.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                            item.description!.toLowerCase().includes(searchValue.toLocaleLowerCase()),
                                    ).length === 0 ? (
                                        <Grid item xs={12} textAlign={"center"}>
                                            <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                            <Typography sx={{ color: "grey", padding: "10px" }}>
                                                No matches found
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <Droppable
                                            droppableId={EDroppableContainerId.MEDIA_AUDIO}
                                            type="DROPPABLE_MEDIA"
                                        >
                                            {(provided, snapshot) => (
                                                <Grid
                                                    ref={provided.innerRef}
                                                    {...provided.droppableProps}
                                                    item
                                                    container
                                                    xs={12}
                                                    columnSpacing={1}
                                                    sx={{
                                                        // marginTop: "5px",
                                                        width: "100%",
                                                    }}
                                                    alignContent={"flex-start"}
                                                >
                                                    {loadedAudioDataAndRecentUploadedAudios
                                                        ?.filter(
                                                            (item: IMediaItem) =>
                                                                searchValue === "" ||
                                                                item
                                                                    .name!.toLowerCase()
                                                                    .includes(searchValue.toLocaleLowerCase()) ||
                                                                item
                                                                    .description!.toLowerCase()
                                                                    .includes(searchValue.toLocaleLowerCase()),
                                                        )
                                                        .sort((a: IMediaItem, b: IMediaItem) => {
                                                            return (
                                                                new Date(b.date).valueOf() - new Date(a.date).valueOf()
                                                            );
                                                        })
                                                        .map((item: IMediaItem, i: number) => (
                                                            <Draggable
                                                                disableInteractiveElementBlocking={true}
                                                                key={item.id}
                                                                draggableId={`${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/audio/${item.id}.${item.filetype}`}
                                                                index={i}
                                                            >
                                                                {(provided, snapshot) => (
                                                                    <Grid
                                                                        item
                                                                        ref={provided.innerRef}
                                                                        xs={audioItemGridSize}
                                                                        sx={{
                                                                            // margin: "15px",
                                                                            marginBottom: "8px",
                                                                            // position: "relative",
                                                                        }}
                                                                    >
                                                                        <VTSidePanelAudioItem
                                                                            nameCutOffPoint={
                                                                                currentSidePanel.currentWidth > 300
                                                                                    ? 14
                                                                                    : 20
                                                                            }
                                                                            ref={(ref) => {
                                                                                // console.log("should be setting", ref);
                                                                                if (ref) {
                                                                                    audioItemRefs.current?.set(
                                                                                        item.id,
                                                                                        ref,
                                                                                    );
                                                                                }
                                                                            }}
                                                                            name={item.name ? item.name : ""}
                                                                            audioSrc={`${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/audio/${item.id}.${item.filetype}`}
                                                                            // onHandleDoubleClick={() => {
                                                                            //     handleDoubleClick(
                                                                            //         EDraggableOptions.AUDIO,
                                                                            //         `${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/audio/${item.id}.${item.filetype}`,
                                                                            //     );
                                                                            // }}
                                                                            sx={{
                                                                                transform:
                                                                                    !snapshot.isDragging ||
                                                                                    (currentlyDragging &&
                                                                                        !snapshot.isDragging)
                                                                                        ? "none !important"
                                                                                        : "auto",
                                                                                ...(snapshot.isDragging && {
                                                                                    border: `4px dashed ${theme.palette.primary.light}`,
                                                                                    borderRadius: "16px",
                                                                                    // paddingBottom: "5px",
                                                                                    // height: "100%"
                                                                                }),
                                                                            }}
                                                                            onPlayButtonClicked={() => {
                                                                                // console.log(
                                                                                //     "audio item refs map",
                                                                                //     audioItemRefs.current,
                                                                                // );
                                                                                if (
                                                                                    lastAudioItemPlayedIdRef.current !==
                                                                                    null
                                                                                ) {
                                                                                    audioItemRefs.current
                                                                                        ?.get(
                                                                                            lastAudioItemPlayedIdRef.current,
                                                                                        )
                                                                                        ?.stopPlaying();
                                                                                }
                                                                                lastAudioItemPlayedIdRef.current =
                                                                                    item.id;
                                                                            }}
                                                                            {...provided.draggableProps}
                                                                            {...provided.dragHandleProps}
                                                                        />{" "}
                                                                        {snapshot.isDragging && (
                                                                            <div
                                                                                style={{
                                                                                    backgroundColor:
                                                                                        theme.palette.background.paper,
                                                                                    width: "100%",
                                                                                    height: "135px",
                                                                                    borderRadius: "15px",
                                                                                    transform: "none !important",
                                                                                }}
                                                                            ></div>
                                                                        )}
                                                                    </Grid>
                                                                )}
                                                            </Draggable>
                                                        ))}
                                                </Grid>
                                            )}
                                        </Droppable>
                                    )}
                                    <div ref={triggerRef} style={{ width: "100%" }}>
                                        {lazyLoadingInProgress && (
                                            <Grid item container columnSpacing={1} sx={{ marginTop: "5px" }}>
                                                {Array.from({ length: 6 }).map(() => {
                                                    return (
                                                        <Grid
                                                            item
                                                            xs={audioItemGridSize}
                                                            sx={{
                                                                marginBottom: "8px",
                                                            }}
                                                        >
                                                            <VTSidePanelAudioItem name={""} audioSrc={""} loading />
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        )}
                                    </div>
                                </Grid>
                            )}
                        </>
                    )}
                </>
            )}

            {activeButton === 2 && (
                <>
                    {scenarioAudiosLoading ? (
                        <Grid item container columnSpacing={1} sx={{ padding: "20px", marginTop: "5px" }}>
                            <Grid
                                item
                                xs={audioItemGridSize}
                                sx={{
                                    marginBottom: "8px",
                                }}
                            >
                                <VTSidePanelAudioItem name={""} audioSrc={""} loading />
                            </Grid>
                            <Grid
                                item
                                xs={audioItemGridSize}
                                sx={{
                                    marginBottom: "8px",
                                }}
                            >
                                <VTSidePanelAudioItem name={""} audioSrc={""} loading />
                            </Grid>
                            <Grid
                                item
                                xs={audioItemGridSize}
                                sx={{
                                    marginBottom: "8px",
                                }}
                            >
                                <VTSidePanelAudioItem name={""} audioSrc={""} loading />
                            </Grid>
                            <Grid
                                item
                                xs={audioItemGridSize}
                                sx={{
                                    marginBottom: "8px",
                                }}
                            >
                                <VTSidePanelAudioItem name={""} audioSrc={""} loading />
                            </Grid>
                        </Grid>
                    ) : (
                        <>
                            {!scenarioAudios || scenarioAudios.length === 0 ? (
                                <Grid item xs={12} textAlign={"center"} sx={{ marginTop: "5px" }}>
                                    <img src={NoAudios} style={{ width: "90%", marginTop: "20px" }} />
                                    <VTTypography sx={{ color: "grey", padding: "10px" }}>
                                        To get started, add first audio file
                                    </VTTypography>
                                </Grid>
                            ) : (
                                // <Droppable droppableId={EDroppableContainerId.MEDIA_2D_IMAGES} type="DROPPABLE_MEDIA">
                                //     {(provided, snapshot) => (
                                //         <>
                                <Grid
                                    sx={{
                                        marginTop: "5px",
                                        padding: "20px",
                                        overflowY: "auto",
                                        height: "calc(100vh - 225px)",
                                        width: "100%",
                                    }}
                                    item
                                    container
                                    alignContent={"flex-start"}
                                >
                                    {scenarioAudios
                                        ?.filter(
                                            (item: IMediaItem) =>
                                                searchValue === "" ||
                                                item.name!.toLowerCase().includes(searchValue.toLocaleLowerCase()) ||
                                                item
                                                    .description!.toLowerCase()
                                                    .includes(searchValue.toLocaleLowerCase()),
                                        )
                                        .sort((a: IMediaItem, b: IMediaItem) => {
                                            return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                        }).length === 0 ? (
                                        <Grid item xs={12} textAlign={"center"}>
                                            <img src={SearchNotFound} style={{ width: "90%", marginTop: "20px" }} />
                                            <Typography sx={{ color: "grey", padding: "10px" }}>
                                                No matches found
                                            </Typography>
                                        </Grid>
                                    ) : (
                                        <Grid
                                            item
                                            container
                                            xs={12}
                                            columnSpacing={1}
                                            sx={{
                                                // marginTop: "5px",
                                                width: "100%",
                                            }}
                                            alignContent={"flex-start"}
                                        >
                                            {scenarioAudios
                                                ?.filter(
                                                    (item: IMediaItem) =>
                                                        searchValue === "" ||
                                                        item
                                                            .name!.toLowerCase()
                                                            .includes(searchValue.toLocaleLowerCase()) ||
                                                        item
                                                            .description!.toLowerCase()
                                                            .includes(searchValue.toLocaleLowerCase()),
                                                )
                                                .sort((a: IMediaItem, b: IMediaItem) => {
                                                    return new Date(b.date).valueOf() - new Date(a.date).valueOf();
                                                })
                                                .map((item: IMediaItem) => (
                                                    <Grid
                                                        key={item.id}
                                                        item
                                                        xs={audioItemGridSize}
                                                        sx={{
                                                            marginBottom: "8px",
                                                            // position: "relative",
                                                        }}
                                                    >
                                                        <VTSidePanelAudioItem
                                                            nameCutOffPoint={
                                                                currentSidePanel.currentWidth > 300 ? 12 : 15
                                                            }
                                                            loading={
                                                                (updateLoading || deleteLoading) &&
                                                                currentItemUpdate == item.id
                                                            }
                                                            ref={(ref) => {
                                                                if (ref) {
                                                                    audioItemRefs.current?.set(item.id, ref);
                                                                }
                                                            }}
                                                            name={item.name ? item.name : ""}
                                                            audioSrc={`${process.env.REACT_APP_NINJA_MEDIA}/${organisation}/audio/${item.id}.${item.filetype}`}
                                                            onPlayButtonClicked={() => {
                                                                if (lastAudioItemPlayedIdRef.current !== null) {
                                                                    audioItemRefs.current
                                                                        ?.get(lastAudioItemPlayedIdRef.current)
                                                                        ?.stopPlaying();
                                                                }
                                                                lastAudioItemPlayedIdRef.current = item.id;
                                                            }}
                                                            onAddButtonClicked={() => {
                                                                setCurrentItemUpdate(item.id);
                                                                updateAllScenarioMedia({
                                                                    id: userId,
                                                                    media_type: EDraggableOptions.AUDIO,
                                                                    media_ids: [item.id],
                                                                });
                                                            }}
                                                        />
                                                    </Grid>
                                                ))}
                                        </Grid>
                                    )}
                                </Grid>
                            )}
                        </>
                    )}
                </>
            )}
        </Grid>
    );
}
